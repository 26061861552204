<script>
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import i18n from '@/i18n';
import { masterMethods, checkPermission } from '@/state/helpers';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: i18n.t('sheduleFile.list'),
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        PageHeader,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            title: i18n.t('sheduleFile.list'),
            index: 1,
            loading: false,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: i18n.t('sheduleFile.list'),
                    active: true
                }
            ],
            listData: [],
            isLoading: false
        };
    },

    mounted() {
        this.getListMasterScheduleFile();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        async getListMasterScheduleFile() {
            this.isLoading = true;
            try {
                const dataRespone = await this.getListScheduleFile();
                this.listData = dataRespone.data;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        handleEdit(idRecord) {
            this.$router.push({
                path: '/master/schedule/add',
                query: {
                    id: idRecord
                }
            });
        },
        handleDelete(k) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteScheduleFile(k).then((data) => {
                        if (data.code == 200) {
                            this.getListMasterScheduleFile();
                            this.$bvToast.toast(`success`, {
                                title: `削除されました。`,
                                variant: 'success',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                    });
                }
            });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div v-if="listData.length > 0 && !isLoading">
            <div class="col-lg-12">
                <div class="text-right mb-3">
                    <button
                        class="btn btn-primary"
                        @click="
                            () => {
                                this.$router.push({ path: '/master/schedule/add' });
                            }
                        "
                    >
                        {{ $t('sheduleFile.resgister') }}
                    </button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div v-for="item in listData" :key="item.id">
                            <div class="d-flex align-items-start mt-3">
                                <div class="w-100">
                                    <div class="d-flex">
                                        <input :disabled="true" class="form-control" v-model="item.title" />
                                        <button type="button" @click="handleEdit(item.id)" class="btn btn-primary mx-2" style="white-space: nowrap">
                                            {{ $t('btn.edit') }}
                                        </button>
                                        <button type="button" @click="handleDelete(item)" class="btn btn-danger" style="white-space: nowrap">
                                            {{ $t('btn.delete') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style></style>
